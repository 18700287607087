<template>
<div :class="{ 'is-inline-block': inline }">
  <div class="level-left">
    <icon
      :class="['level-item', iconClass]"
      :icon="(value) ? yesIcon : noIcon"
      :type="iconType"/>
    <span v-if="yesText || noText" v-text="(value) ? yesText: noText" class="level-item"></span>
  </div>
</div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    yesIcon: {
      type: String,
      default: 'check'
    },
    noIcon: {
      type: String,
      default: 'times'
    },
    iconType: {
      type: String,
      default: 'fas'
    },
    yesClass: {
      type: String,
      default: 'has-text-success'
    },
    noClass: {
      type: String,
      default: 'has-text-danger'
    },
    yesText: {
      type: String,
      default: ''
    },
    noText: {
      type: String,
      default: ''
    },
    inline: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    iconClass() {
      return (this.value) ? this.yesClass : this.noClass
    }
  }
}
</script>
