<template functional>
  <section class="content" :class="props.size ? `is-${props.size}` : null">
    <slot/>
  </section>
</template>

<script>
export default {
  props: {
    size: {
      type: String|undefined,
      validator: value => value !== 'undefined'
        ? ['small', 'medium', 'large'].includes(value)
        : true
    }
  }
}
</script>
