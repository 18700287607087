<template>
  <div class="flyout-wrapper">
    <div class="flyout-overlay" @click="toggle" :class="{ visible }"></div>
    <div class="flyout" style="width: 340px" :class="[{ visible }, `from-${position}`]">
      <header class="flyout-header" v-if="header">
        <div class="flyout-header-icon">
          <button @click="toggle" class="delete"/>
        </div>
        <p class="flyout-header-title" v-text="title"></p>
        <div class="flyout-header-icon" v-if="$slots.headerRight">
          <slot name="headerRight"/>
        </div>
      </header>
      <div class="flyout-content">
        <slot/>
      </div>
      <div class="flyout-footer" v-if="$slots.footer">
        <slot name="footer"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    title: String,
    position: {
      type: String,
      default: 'right'
    },
    header: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    visible: false,
  }),

  methods: {
    toggle() {
      this.visible = !this.visible
    }
  }

}
</script>
